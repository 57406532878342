.home {
  color: #2525a5;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-heading {
    text-align: center;
    font-size: 3em;
  }
}
