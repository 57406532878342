.t3nav {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__topbar {
    height: 25px;
    background-color: #fff;
    width: 100%;
  }
}
