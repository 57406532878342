.svbutton {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  &_button {
    color: #fff;
    background-color:#364dc9; 
    width: 333px;
    padding: 0.4em 0.4em;
    margin-top: 18px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    border-radius: 0.3em;
    letter-spacing: 0.04em;
    font-weight: bold;
    
  }
}
