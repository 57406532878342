.t2nav {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;

  box-shadow: 2px 2px 5px #cacaca;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
