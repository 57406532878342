@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap");

@import "../components/t3Footer";
@import "../components/t3Nav";

.t3layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .landing,
  .error,
  .success {
    box-shadow: none;
  }

  .landing-heading,
  .landing-valtext,
  .landing_typo,
  .error_heading,
  .error_typo,
  .success_heading,
  .success_typo {
    text-align: left;
    width: 100%;
    padding: 0.3em 0;
  }

  .landing-valtext {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 22px;
  }

  .landing-heading,
  .error_heading,
  .success_heading {
    font-size: 28px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .landing_typo,
  .error_typo,
  .success_typo {
    font-size: 22px;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }
}
