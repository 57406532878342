.success {
  margin-top: 47px;
  box-shadow: 2px 2px 5px #cacaca;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 25px 0;

  &_heading {
    font-size: 2.33em;
    font-weight: bold;
    line-height: 1.25;
    border-bottom: 2px solid #3928d6;
    margin-bottom: 20px;
  }

  &_typo {
    padding: 0.3em 0.5em;
    line-height: 1.55;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.0075em;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .success {
    margin-top: 30px;
    border-radius: 4px;
  
    &_heading {
      font-size: 24px;
      text-align: center;
      width: 100%;
    }
  
    &_typo {
      padding: 0 1vw;
      text-align: center;
      font-size: 20px;
      margin-bottom: 1vw;
    }
  }
}
