.t3footer {
  height: 80px;
  background-color: black;

  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}
