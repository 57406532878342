// Views
@import "./views/home";
@import "./views/landing";
@import "./views/svbutton";
@import "./views/success";
@import "./views/error";
@import "./views/expired";

// Layouts
@import "./layouts/t1Layout";
@import "./layouts/t2Layout";


// Components
@import "./components/t1Nav";
@import "./components/t2Nav";


*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "SF-Regular", "Helvetica";
  overscroll-behavior-y: none;
}

.container {
  max-width: 1070px;
  width: 90%;
  margin: auto;
}

.abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-bg {
  background-color: rgba($color: #8e8e8e, $alpha: 0.5);
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3928d6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-top: 2rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .container {
    max-width: 100%;
    width: 95%;

  }
}
