.landing {
  margin-top: 47px;
  box-shadow: 2px 2px 5px #cacaca;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 0;
  &-heading {
    font-size: 2.33em;
    font-weight: bold;
    line-height: 1.25;
    border-bottom: 2px solid #f71616;
    margin-bottom: 20px;
  }
  &-valtext {
    margin: 1.2rem 0;
    font-size: 20px;
  }

  &_typo {
    margin: 0.3em 0;
    line-height: 1.55;
    font-size: 1.3rem;
    padding: 0 0.5rem;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.0075em;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .landing {
    margin-top: 30px;
    border-radius: 4px;
    &-heading {
      border-bottom: 1.5px solid #3928d6;
      font-size: 24px;
    }
    &-valtext {
      padding: 0 1vw;
      text-align: center;
      line-height: 1.5em;
      font-weight: 400;
    }

    &_typo {
      padding: 0 1vw;
    }
  }
}
